import React from 'react'
import Shape1 from '../svg/shape-1.svg'
import Shape2 from '../svg/shape-2.svg'
import Shape3 from '../svg/shape-3.svg'

const RandomShape = () => {
  const classes =
    'absolute inset-y-0 -inset-x-8 md:inset-x-0 w-115 md:w-full h-full'
  const shapes = [
    <Shape1 className={classes} style={{ willChange: `transform` }} />,
    <Shape2 className={classes} style={{ willChange: `transform` }} />,
    <Shape3 className={classes} style={{ willChange: `transform` }} />,
  ]
  return shapes[Math.floor(Math.random() * shapes.length)]
}
export default RandomShape
